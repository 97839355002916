<template>
    <v-container class="pa-0" fluid>
      <loading-bar v-model="loading"></loading-bar>
      <v-row dense v-if="tournament.isTournament">
        <v-col cols="12" class="pa-0 text-right">
          <v-btn
            color="color3"
            class="xsmall pa=0 ma-0"
            text
            @click.stop="advanced = !advanced"
            :disabled="!edit || forceAdv"
          >{{advanced || forceAdv ? 'simple' : 'advanced'}}</v-btn>
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row dense v-if="advanced || forceAdv">
          <v-col cols="12">
            <v-switch
              label="Free Play Round"
              v-model="round.freePlay"
              color="success"
              :disabled="!edit"
            ></v-switch>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field label="Round Name" v-model="round.name" :disabled="!edit" hide-details></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <date-time-picker
            :datetime.sync="round.dtStart"
            label="Play Start"
            :disabled="!edit"
          ></date-time-picker>
        </v-col>
      </v-row>
      <v-row dense class="mb-3">
          <setting-toggle
            :dataId="round.id"
            :disabled="round.locked"
            label="Published"
            propName="published"
            :isRoundProp="true"
            iClass="mr-3 shrink mt-0"
          ></setting-toggle>
          <setting-toggle
            :dataId="round.id"
            :disabled="round.locked"
            label="Hide Match Times"
            propName="hideTimes"
            :isRoundProp="true"
            iClass="mr-3 shrink mt-0"
          ></setting-toggle>
          <setting-toggle
            :dataId="round.id"
            :disabled="round.locked"
            label="Hide Refs"
            propName="noRefs"
            :isRoundProp="true"
            :jProp="true"
            iClass="mr-3 shrink mt-0"
          ></setting-toggle>
          <setting-toggle
            :dataId="round.id"
            :disabled="round.locked"
            label="Hide Seeds"
            propName="noSeeds"
            :isRoundProp="true"
            :jProp="true"
            iClass="mr-3 shrink mt-0"
          ></setting-toggle>
          <setting-toggle
            :dataId="round.id"
            label="Locked"
            propName="locked"
            :isRoundProp="true"
            :disabled="division.complete"
            iClass="mr-3 shrink mt-0"
          ></setting-toggle>
      </v-row>
      <!-- TEAM SELECTION -->
      <v-expand-transition>
        <v-row dense justify="center" v-if="!round.freePlay" class="py-0">
          <v-col cols="12">
            <v-card flat tile>
              <v-toolbar dense :color="edit ? 'color1 color1Text--text' : 'grey lighten-3'" flat>
                <v-toolbar-title>Teams</v-toolbar-title>
              </v-toolbar>
              <template
                v-for="(selector, i) in round.teamSelectors"
              >
                <v-row dense justify="center"
                  :key="`plus_${i}`"
                  v-if="i > 0"
                >
                  <v-col class="text-center" cols="12" >
                    <v-icon>fas fa-plus</v-icon>
                  </v-col>
                </v-row>
                <team-selection
                  :key="`selector_${i}`"
                  :ref="`selector_${i}`"
                  :selector="selector"
                  :edit="edit"
                  :round="round"
                  :fromSelect="fromSelections"
                  :noEdit="round.poolPlay && !!roundCreated && user.id !== 1"
                  :divisionId="divisionId"
                ></team-selection>
                <v-row dense v-if="user && user.vbl" :key="`selector_${i}_id`">
                  <v-col cols="12" class="xsmall">
                    SelectorId: {{selector.id}}
                  </v-col>
                </v-row>
              </template>
            </v-card>
          </v-col>
          <v-btn color="color3" text @click.stop="addSelector" small v-if="edit">Add More Teams</v-btn>
        </v-row>
      </v-expand-transition>
      <!-- PLAY STYLE -->
      <v-expand-transition>
        <v-row dense v-if="!round.freePlay">
          <v-col cols="12" sm="6">
            <v-radio-group v-model="playType" row :disabled="!edit" hide-details>
              <v-radio label="Pool Play" value="pool" color="color3"></v-radio>
              <v-radio label="Bracket Play" value="bracket" color="color3"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-expand-transition>
      <!-- BRACKET -->
      <v-expand-transition>
        <v-card flat tile v-if="isBracketPlay">
          <v-toolbar dense :color="edit ? 'color1 color1Text--text' : 'grey lighten-3'" flat>
            <v-toolbar-title>Bracket</v-toolbar-title>
          </v-toolbar>
          <v-row dense>
            <v-col cols="12">
              <bracket-settings
                :round="round"
                :edit="edit"
                :pad="true"
                :division="division"
              ></bracket-settings>
            </v-col>
          </v-row>
        </v-card>
      </v-expand-transition>

      <!-- POOL PLAY -->
      <v-row dense v-if="isPoolPlay && round.id > 0">
        <v-alert type="info" :value="!round.teams.length" text>
          There are no teams for this division yet.
        </v-alert>
        <v-col cols="12" v-if="round.freePlay" class="text-center">
          <!-- Match Up Creator -->
          <match-up-creator
            :time="round.dtStart"
            :roundId="round.id"
            :disabled="edit"
          ></match-up-creator>
        </v-col>
        <template v-else-if="!round.flights.length">
          <v-col cols="12" v-if="!locked">
            <v-btn
              color="color3 color3Text--text"
              class="mr-2"
              @click.stop="goSeedTeams"
              v-if="!round.isFullySeeded"
              :disabled="edit"
            >Seed Teams</v-btn>
            <pool-wizard-dialog
              :round="round"
              text="Create Pools"
              :disabled="blockPoolCreate || edit"
            ></pool-wizard-dialog>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12">
            <flight-settings
              :flight="round.flights[0]"
              :title="'Pool Play'"
              :edit="edit"
              :teamCount="round.teamCount"
            ></flight-settings>
          </v-col>
          <v-col cols="12" v-if="edit && !round.freePlay">
            <pool-wizard-dialog :round="round" text="Re-Create Pools" :disabled="round.teams.length === 0"></pool-wizard-dialog>
          </v-col>
        </template>
      </v-row>
      <v-row dense>
        <v-col cols="12" v-if="edit && round.id === -99">
          <v-checkbox
            :label="`Apply To Divisions without a round ${round.number}`"
            v-model="applyToAll"
          ></v-checkbox>
        </v-col>
      </v-row>

      <!-- EDIT BUTTONS -->
      <v-row dense justify="end" v-if="!locked">
        <v-fab-transition>
          <v-btn
            color="color3 color3Text--text"
            key="save"
            v-if="edit && dirty"
            fab
            small
            class="my-0 mr-1"
            @click.stop="save"
            :loading="saving"
            :disabled="selectorsInvalid"
          >
            <v-icon small>fas fa-save</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            color="color3--text"
            key="trash"
            v-if="edit"
            fab
            small
            class="my-0 mr-1"
            @click.stop="dialog = true"
            :loading="saving"
          >
            <v-icon small>fas fa-trash</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            :color="activeFab.color"
            :key="activeFab.icon"
            v-model="fab"
            fab
            small
            class="my-0"
            @click.stop="edit ? cancel() : editMode()"
          >
            <v-icon small>{{activeFab.icon}}</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-row>
      <!-- DELETE ROUND DIALOG -->
      <v-dialog
        v-if="edit"
        v-model="dialog"
        max-width="500px"
      >
        <v-card>
          <v-toolbar color="color2 color2Text--text">
            <v-toolbar-title>Delete Round?</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div class="title">Are you sure?</div>
            Deleting a round can not be undone.
          </v-card-text>
          <v-container>
            <v-row dense justify="center">
              <v-btn
                color="success"
                class="mr-2"
                @click.stop="deleteRound"
                :loading="saving"
                :disabled="false"
              >Yes</v-btn>
              <v-btn color="error" @click.stop="dialog = false">no</v-btn>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="invalidSnack"
        vertical
        color="error"
      >
        Please check the errors above
        <v-btn text color="white" @click.native="invalidSnack = false">Close</v-btn>
      </v-snackbar>
      <v-row dense v-if="user && user.vbl">
        <v-col cols="12" class="xsmall">
          RoundId: {{round.id}}
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import TeamSelector from '@/classes/TeamSelector'
import { BracketTypes } from '@/classes/BracketTypes'
import { mapGetters } from 'vuex'
import SettingToggle from '@/components/Tournament/QuickSettings/SettingToggle.vue'
import DateTimePicker from '@/components/Utils/DateTimePicker.vue'
const PoolWizardDialog = () => import('./PoolWizard/PoolWizardDialog.vue')
const FlightSettings = () => import('./FlightSettings.vue')
const BracketSettings = () => import('./BracketSettings.vue')
const TeamSelection = () => import('./TeamSelection.vue')
const MatchUpCreator = () => import('@/components/Tournament/Courts/MatchUpCreatorDialog')

export default {
  props: ['round', 'deletable', 'fromSelect', 'divisionId', 'locked', 'copied'],
  data () {
    return {
      editMe: false,
      fab: false,
      saving: false,
      dialog: false,
      loading: false,
      applyToAll: false,
      invalidSnack: false,
      advanced: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'getDivision', 'user']),
    forceAdv () {
      return this.round.freePlay
    },
    selectorsInvalid () {
      return !!this.round.teamSelectors.filter(f => ['MAPPED', 'MATCH_LOSERS'].includes(f.type) && !f.map).length
    },
    selectorsInvalids () {
      return this.round.teamSelectors.filter(f => f.type === 'MAPPED' && !f.map)
    },
    division () {
      return this.getDivision(this.divisionId)
    },
    roundProps () {
      return this.tournament.jProps && this.tournament.jProps.rounds && this.tournament.jProps.rounds[this.round.id]
    },
    roundCreated () {
      return (this.round.poolPlay && this.round.pools.length) || (this.round.bracketPlay && this.round.published)
    },
    activeFab () {
      return this.edit ? { color: 'red white--text', icon: 'fas fa-times' } : { color: 'color3Text color3--text', icon: 'fas fa-pencil-alt' }
    },
    dirty () {
      return this.round.isDirty()
    },
    fromSelections () {
      return this.fromSelect.filter(f => f.number !== this.round.number)
    },
    playType: {
      get () {
        return this.round.poolPlay ? 'pool' : 'bracket'
      },
      set (val) {
        if (val) {
          if (val === 'pool') {
            this.round.toPoolPlay()
          } else {
            this.round.toBracketPlay()
          }
        }
      }
    },
    isPoolPlay () {
      return this.round.poolPlay
    },
    isBracketPlay () {
      return !this.round.poolPlay && this.round.bracketPlay
    },
    edit () {
      return (this.round.id === 0) || this.editMe
    },
    bracketTypes () {
      return BracketTypes
    },
    teamCount () {
      return this.round.teamCount || this.round.teams.length
    },
    blockPoolCreate () {
      return this.teamCount < 3 || (this.round.hasRegSelector && !this.round.isFullySeeded)
    },
    divisionStandings () {
      return this.division.standings && this.division.standings
    },
    divisionStandingGroups () {
      return this.divisionStandings && this.divisionStandings.groupTeamsIds
    }
  },
  methods: {
    goSeedTeams () {
      this.$router.push({ name: 'division-teams', params: { seed: true } })
    },
    editMode () {
      this.round.edit()
      this.editMe = true
    },
    cancel () {
      if (this.round.id === 0) {
        this.$emit('cancel-new-round')
        return
      }
      this.round.restore()
      this.editMe = false
    },
    hasErrors () {
      let invalid = false
      for (let i = 0; i < this.round.teamSelectors.length; i++) {
        const ref = this.$refs[`selector_${i}`]
        if (!this.round.freePlay && ref && ref[0].hasErrors()) invalid = true
      }
      this.invalidSnack = invalid
      return invalid
    },
    save () {
      if (this.dirty) {
        if (this.hasErrors()) return
        console.log('saving')
        this.saving = true

        if (this.round.id === 0) {
          this.$VBL.post.rounds(this.round.dto, this.divisionId, this.applyToAll)
            .then(() => {
              console.log('then')
              this.editMe = false
              this.$emit('round-created')
            })
            .catch(err => {
              console.log(err.response)
            })
            .finally(() => {
              this.saving = false
            })
        } else {
          this.$VBL.patch.day(this.round.dto, this.divisionId)
            .then(() => {
              console.log('then')
              this.editMe = false
            })
            .catch(err => {
              console.log(err.response)
            })
            .finally(() => {
              this.saving = false
            })
        }
      }
    },
    init () {
      if (this.round.teamSelectors.length === 0) {
        this.addSelector()
      }
      if (this.copied) {
        this.save()
      }
    },
    addSelector () {
      this.round.teamSelectors.push(new TeamSelector(this.$VBL, null))
    },
    deleteRound () {
      this.saving = true
      this.$VBL.delete.round(this.round.id)
        .then(() => {
          this.dialog = false
          this.editMe = false
        })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.saving = false
        })
    },
    togglePublish () {
      this.loading = true
      this.round.published = !this.round.published
      this.round.patch({
        published: this.round.published
      })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    toggleTimes () {
      this.loading = true
      this.round.hideTimes = !this.round.hideTimes
      this.round.patch({
        hideTimes: this.round.hideTimes
      })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    toggleLocked () {
      this.loading = true
      this.round.locked = !this.round.locked
      this.round.patch({
        locked: this.round.locked
      })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    toggle (field) {
      this.round[field] = !this.round[field]
      if (!this.round.id) return
      this.loading = true
      const dto = {}
      dto[field] = this.round[field]
      this.round.patch(dto)
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    round: function (val) {
      this.init()
    },
    'round.teamSelectors.length': function (n, o) {
      if (o && !n) {
        this.init()
      }
    },
    'round.freePlay': function (v) {
      if (v) {
        this.round.toPoolPlay()
        const ts = this.round.teamSelectors[0]
        ts.fromRoundId = null
        ts.fromStandings = null
        ts.type = 'ALL'
      }
    }
  },
  components: {
    'flight-settings': FlightSettings,
    'bracket-settings': BracketSettings,
    'team-selection': TeamSelection,
    'pool-wizard-dialog': PoolWizardDialog,
    'date-time-picker': DateTimePicker,
    SettingToggle,
    MatchUpCreator
  },
  mounted () {
    this.init()
  }
}
</script>

<style>

</style>
