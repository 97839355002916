<template>
  <v-btn
    v-if="button"
    color="color3"
    text class="xsmall ma-0"
    :disabled="disabled"
    :loading="loading"
    @click.stop="toggle"
  >
    {{myLabel}}
  </v-btn>
  <v-btn
    v-else-if="fab"
    :color="color || 'color3 color3Text--text'"
    small fab
    :disabled="disabled"
    :loading="loading"
    @click.stop="toggle"
  >
    <v-icon small>{{icon}}</v-icon>
  </v-btn>
  <v-checkbox
    v-else-if="small"
    :class="iClass"
    hide-details
    :label="label"
    v-model="myProp"
    :disabled="disabled"
    :loading="loading"
    :color="color || 'success'"
  ></v-checkbox>
  <v-switch
    v-else
    :class="iClass"
    hide-details
    :label="label"
    v-model="myProp"
    :disabled="disabled"
    :loading="loading"
    :color="color || 'success'"
  ></v-switch>
</template>

<script>
import * as mutations from '@/store/MutationTypes'
export default {
  props: ['disabled', 'propName', 'label', 'dataId', 'isRoundProp', 'isDivisionProp', 'jProp', 'propList', 'button', 'trueLabel', 'falseLabel', 'fab', 'icon', 'color', 'iClass', 'small', 'invert'],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    myLabel () {
      return (this.myProp ? this.trueLabel : this.falseLabel) || this.label
    },
    tournament () {
      return this.$store.getters.tournament
    },
    division () {
      return this.dataId && this.isDivisionProp && this.$store.getters.getDivision(this.dataId)
    },
    divisionJProps () {
      return this.jProp && this.dataId && this.isDivisionProp && this.$store.getters.getDivisionJProps(this.dataId)
    },
    divisionPropList () {
      return this.division && this.division.props
    },
    round () {
      return this.dataId && this.isRoundProp && this.$store.getters.getRound(this.dataId)
    },
    roundJProps () {
      return this.jProp && this.dataId && this.isRoundProp && this.$store.getters.getRoundJProps(this.dataId)
    },
    divisionProp () {
      return this.jProp ? this.divisionJProps && this.divisionJProps[this.propName] : this.propList ? (this.divisionPropList && this.divisionPropList.includes(this.propName)) : this.division && this.division[this.propName]
    },
    roundProp () {
      return this.jProp ? this.roundJProps && this.roundJProps[this.propName] : this.round && this.round[this.propName]
    },
    myProp: {
      get: function () {
        const val = this.isDivisionProp ? this.divisionProp : this.roundProp
        return this.invert ? !val : val
      },
      set: function (val) {
        if (this.invert) val = !val
        this.jProp ? this.setJProp(val) : this.isRoundProp ? this.setRoundProp(val) : this.setDivisionProp(val)
      }
    }
  },
  methods: {
    toggle () {
      this.myProp = !this.myProp
    },
    setDivisionProp (val) {
      if (this.propList) {
        if (val) {
          !this.division.props.includes(this.propName) && this.division.props.push(this.propName)
        } else {
          this.division.props = this.division.props.filter(f => f !== this.propName)
        }
        this.saveDivisionPropList()
      } else {
        this.division[this.propName] = val
        this.saveDivisionProp()
      }
    },
    setRoundProp (val) {
      this.round[this.propName] = val
      this.saveRoundProp()
    },
    setJProp (val) {
      const dto = {}
      dto[this.propName] = val
      var mut = this.isRoundProp ? mutations.SET_ROUND_JPROP : mutations.SET_DIVISION_JPROP
      this.$store.commit(mut, { id: this.dataId, val: dto })
      this.saveJProp()
    },
    saveJProp () {
      if (!this.tournament.id) return
      this.loading = true
      this.tournament.saveJProps()
        .finally(() => {
          this.loading = false
        })
    },
    saveRoundProp () {
      if (!this.round.id) return
      this.loading = true
      const dto = {}
      dto[this.propName] = this.round[this.propName]
      this.round.patch(dto)
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveDivisionProp () {
      if (!this.division.id) return
      this.loading = true
      const dto = {}
      dto[this.propName] = this.division[this.propName]
      this.division.patch(dto)
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveDivisionPropList () {
      if (!this.division.id) return
      this.loading = true
      const dto = {
        props: this.divisionPropList
      }
      this.division.patch(dto)
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style>

</style>
